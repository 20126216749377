import { Button, Form, Input, Modal, Select, Space } from 'antd';
import { useState } from 'react';

import { DuplicatePOIPicker } from './DuplicatePOIPicker';
import { useCategoriesQuery } from '../../app/api';
import { validateImage } from '../../app/validateImage';
import { apiPOIToFormPOI } from '../../utils/poiUtils';
import { ImageUpload } from '../ImageUpload';

const MAX_PIXELS = 1200000;
const IMG_ERROR_MSG = `Resolution of image you are trying to upload is too high(width x height > ${MAX_PIXELS}), please try to use picture with lower resolution (E.g. 1200x1000)`;

const getCategoriesAsOptions = categories => {
  const categoriesAsOptions = categories.map(c => ({
    label: c.label,
    value: c.id,
  }));

  categoriesAsOptions.sort((a, b) => a.label.localeCompare(b.label));
  return categoriesAsOptions;
};

const getFormValuesForPoi = poi => ({
  title: poi.title,
  description: poi.description,
  url: poi.url,
  webUrl: poi.webUrl,
  videoUrl: poi.videoUrl,
  phoneNumber: poi.phoneNumber,
  image: poi.image,
  categories: poi.categories,
});

function CategoriesSelect({ value, onChange, ...other }) {
  const categoriesQuery = useCategoriesQuery();
  const categories = categoriesQuery?.data?.data || [];
  return (
    <Select
      mode="multiple"
      allowClear
      placeholder="Categories"
      labelInValue
      value={value?.map(v => ({ label: v.label, value: v.id }))}
      onChange={value => {
        const currentCategories = value.map(v =>
          categories.find(c => c.id === v.value)
        );
        onChange(
          currentCategories?.map(cc => ({
            label: cc.label,
            id: cc.id,
            color: cc.color,
          }))
        );
      }}
      optionFilterProp="label"
      options={getCategoriesAsOptions(categories)}
      {...other}
    />
  );
}

export function POIEditModal({ visible, onCancel, onOk, onDelete, poi }) {
  const [form] = Form.useForm();
  const images = Form.useWatch('image', form);
  const image = images?.[0];
  const [okButtonDisabled, setOkButtonDisabled] = useState(false);

  const handlePOIPrefill = copiedPOI => {
    const copiedPOIInFormFormat = apiPOIToFormPOI(copiedPOI);
    const newFormValues = getFormValuesForPoi(copiedPOIInFormFormat);
    form.setFieldsValue(newFormValues);
  };

  return (
    <Modal
      title="Edit POI"
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button
            key="delete"
            danger
            onClick={onDelete}
            style={{ float: 'left' }}
          >
            Delete
          </Button>
          <Space>
            <Button key="cancel" type="secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              key="ok"
              type="primary"
              onClick={() => form.submit()}
              disabled={okButtonDisabled}
            >
              OK
            </Button>
          </Space>
        </>
      }
    >
      <DuplicatePOIPicker onPrefill={handlePOIPrefill} />
      <Form
        form={form}
        layout="vertical"
        name="poiForm"
        onFinish={onOk}
        initialValues={getFormValuesForPoi(poi)}
      >
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="image">
          <ImageUpload
            maxCount={1}
            showUploadList={false}
            validationMethod={f => validateImage(f, MAX_PIXELS, IMG_ERROR_MSG)}
            onUploadStart={() => setOkButtonDisabled(true)}
            onUploadFinish={() => setOkButtonDisabled(false)}
          />
        </Form.Item>
        {image && (
          <img src={image.url} alt={image.name} style={{ width: '100%' }} />
        )}
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="categories" label="Categories">
          <CategoriesSelect />
        </Form.Item>
        <Form.Item name="url" label="More info URL">
          <Input placeholder="Must start with http / https" />
        </Form.Item>
        <Form.Item name="webUrl" label="Web URL">
          <Input placeholder="Must start with http / https" />
        </Form.Item>
        <Form.Item name="videoUrl" label="Video URL">
          <Input placeholder="Must start with http / https" />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone Number">
          <Input placeholder="Phone Number" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
